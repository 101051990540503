<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Escáner Guías</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Escáner Guías</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section class="content">
          <div class="container-fluid">
            <div class="card" style="display: inherit">
              <div class="bg-light pt-2 p-2">
                <div class="row col-12">
                  <div
                    class="form-group pl-4 col-md-3"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.escanerGuias.cargarArchivos'
                      )
                    "
                  >
                    <label for="link_pdf">Subir PDF</label>
                    <div class="form-group">
                      <input
                        type="file"
                        id="link_pdf"
                        ref="link_pdf"
                        class="form-control-file"
                        accept=".pdf, .PDF, .jpg, .JPG, .png, .PNG"
                        @change="elegirDirectorio()"
                        multiple
                      />
                    </div>
                    <div>
                      <div
                        v-if="loading"
                        class="spinner-border text-success"
                        role="status"
                      ></div>
                      <b v-if="loading" class="text-success"> Cargando...</b>
                      <button
                        v-else
                        id="guardar_documento"
                        type="button"
                        class="btn btn-lg btn-success"
                        data-html="true"
                        title="Guardar Documento"
                        data-toggle="tooltip"
                        @click="saveFiles()"
                      >
                        <span>Cargar</span>
                      </button>
                    </div>
                  </div>

                  <!-- <div class="form-group col-md-2"></div> -->
                  <div
                    class="col-lg-3 col-md-4 d-flex align-items-stretch flex-column"
                    style="cursor: pointer"
                    @click="filtro(1)"
                  >
                    <div class="small-box bg-gradient-success">
                      <div class="inner">
                        <h3>{{ countCargar }}</h3>
                        <p>Escáneres Por Confirmar</p>
                      </div>
                      <div class="icon">
                        <i class="fas fa-upload text-white"></i>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-lg-3 col- d-flex align-items-stretch flex-column"
                    style="cursor: pointer"
                    @click="filtro(2)"
                  >
                    <div class="small-box bg-frontera">
                      <div class="inner text-white">
                        <h3>{{ countExistentes }}</h3>
                        <p>Escáneres Existentes</p>
                      </div>
                      <div class="icon">
                        <i class="fas fa-file-contract text-white"></i>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-lg-3 col-md-6 d-flex align-items-stretch flex-column"
                    style="cursor: pointer"
                    @click="filtro(3)"
                  >
                    <div class="small-box bg-gradient-danger">
                      <div class="inner">
                        <h3>{{ countSinGuias }}</h3>
                        <p>Guía No Encontrada</p>
                      </div>
                      <div class="icon">
                        <i class="far fa-file-excel text-white"></i>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      !$store.getters.can(
                        'hidrocarburos.escanerGuias.cargarArchivos'
                      )
                    "
                    class="col-lg-3 d-flex align-items-stretch flex-column"
                    style="cursor: pointer"
                    @click="filtro(2)"
                  >
                    <div class="small-box bg-light">
                      <div class="inner text-info">
                        <h3>{{ listaPdfs.length ? listaPdfs.length : 0 }}</h3>
                        <p>Total Archivos</p>
                      </div>
                      <div class="icon">
                        <i class="fas fa-file-contract text-info"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="progress" v-if="progress">
                  <div
                    class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                    :class="
                      progress < 50
                        ? 'bg-danger'
                        : progress > 50 && progress < 98
                        ? 'bg-warning'
                        : 'bg-success'
                    "
                    role="progressbar"
                    :style="'width:' + progress + '%'"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {{ progress }}%
                  </div>
                </div>
              </div>
              <div
                class="row justify-content-center"
                v-if="
                  listaPdfs.length > 0 &&
                    $store.getters.can(
                      'hidrocarburos.escanerGuias.cargarArchivos'
                    )
                "
                style="cursor: pointer"
                @click="filtro()"
              >
                <div
                  class="col-lg-3 d-flex align-items-stretch flex-column"
                  style="cursor: pointer"
                  @click="filtro(2)"
                >
                  <div class="small-box bg-light">
                    <div class="inner text-info">
                      <h3>{{ listaPdfs.length }}</h3>
                      <p>Total Archivos</p>
                    </div>
                    <div class="icon">
                      <i class="fas fa-file-contract text-info"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid">
            <div class="card col-sm-12">
              <div class="card-body">
                <div
                  class="alert alert-warning col-12"
                  v-if="listaPdfsFiltro.length < 1"
                >
                  <h5>
                    <i class="icon fas fa-exclamation-triangle"></i>
                    Atención!
                  </h5>
                  No se encontraron archivos cargados.
                </div>
                <div class="row" v-if="listaPdfsFiltro.length > 0">
                  <div
                    class="card-body"
                    :class="visualizacion ? 'card col-sm-6' : 'card col-sm-12'"
                  >
                    <div class="alert alert-info" v-if="countCargar > 0">
                      <h5>
                        <i class="fas fa-question-circle"></i>
                        <!-- <i class="icon fas fa-exclamation-triangle"></i> -->
                        Atención!
                      </h5>
                      Para visualizar un escáner, deberá hacer click sobre el
                      nombre (GUIA) que se desea visualizar.
                    </div>
                    <div
                      class="list-group"
                      style="overflow-y: scroll"
                      :style="
                        listaPdfsFiltro.length < 3
                          ? { height: '200px' }
                          : { height: '525px' }
                      "
                    >
                      <div>
                        <div
                          class="list-group-item list-group-item-action flex-column align-items-start"
                          v-for="pdf in listaPdfsFiltro"
                          :key="pdf.id"
                        >
                          <div
                            class="d-flex w-100 justify-content-between mb-0"
                          >
                            <h5
                              class="mb-1"
                              style="cursor: pointer"
                              @click="visualizarPdf(pdf.link, pdf.estado)"
                              :class="
                                !pdf.link
                                  ? 'text-danger'
                                  : pdf.estado == 3
                                  ? 'text-primary'
                                  : 'text-success'
                              "
                            >
                              <b>Guía: </b>
                              {{
                                pdf.viaje_id
                                  ? pdf.viaje.numero_guia.slice(0, -1) +
                                    "-" +
                                    pdf.viaje.numero_guia.slice(-1)
                                  : pdf.numero_guia.slice(0, -1) +
                                    "-" +
                                    pdf.numero_guia.slice(-1)
                              }}
                            </h5>
                            <small>
                              <div
                                v-if="pdf.link && pdf.estado == 3"
                                class="d-inline mr-2"
                              >
                                <span class="text-primary">
                                  Cuenta con un archivo asignado
                                  <i class="fas fa-long-arrow-alt-right"></i>
                                </span>
                                <a
                                  :href="uri_docs + pdf.link"
                                  class="btn btn-sm bg-danger ml-2 color-white"
                                  target="_blank"
                                  ><i class="fas fa-file-pdf"></i>
                                </a>
                              </div>

                              <button
                                v-if="
                                  $store.getters.can(
                                    'hidrocarburos.escanerGuias.descartar'
                                  )
                                "
                                type="button"
                                class="btn btn-sm bg-danger"
                                @click="descartarPdf(pdf)"
                              >
                                <i class="fas fa-trash-alt"></i></button
                            ></small>
                          </div>
                          <div class="card-body pb-0 pt-1">
                            <div
                              v-if="pdf.viaje_type == 'App\\Viaje'"
                              class="row"
                              style="height: 20px"
                            >
                              <label class="col-md-4 col-sm-4 col-xs-6"
                                ><b>Viaje:</b>
                              </label>
                              <span
                                class="col-md-8 col-sm-8 col-xs-6 text-right"
                              >
                                {{ pdf.viaje_id ? pdf.viaje_id : "---------" }}
                              </span>
                            </div>
                            <div
                              v-if="pdf.viaje_type == 'App\\DetGuiaRegalias'"
                              class="row"
                              style="height: 20px;"
                            >
                              <label class="col-md-4 col-sm-4 col-xs-6"
                                ><b>Tipo Operación:</b>
                              </label>
                              <span
                                class="col-md-8 col-sm-8 col-xs-6 text-right"
                              >
                                {{
                                  pdf.viaje_id
                                    ? pdf.viaje.nTipoOperacion
                                    : "---------"
                                }}
                              </span>
                            </div>
                            <div
                              v-if="pdf.viaje_type == 'App\\Viaje'"
                              class="row"
                              style="height: 20px"
                            >
                              <label class="col-xs-4 col-sm-4 col-xs-6"
                                ><b>Ruta:</b>
                              </label>
                              <span
                                class="col-md-8 col-sm-8 col-xs-6 text-right"
                              >
                                {{
                                  pdf.viaje_id
                                    ? pdf.viaje.ruta.nombre
                                    : "---------"
                                }}
                              </span>
                            </div>
                            <div v-else class="row" style="height: 20px">
                              <label class="col-xs-4 col-sm-4 col-xs-6"
                                ><b>Ruta:</b>
                              </label>
                              <span
                                class="col-md-8 col-sm-8 col-xs-6 text-right"
                              >
                                {{
                                  pdf.viaje_id && pdf.viaje.sitio_origen
                                    ? pdf.viaje.sitio_origen.nombre +
                                      " - " +
                                      pdf.viaje.sitio_destino
                                    : "---------"
                                }}
                              </span>
                            </div>
                            <div class="row" style="height: 20px">
                              <label class="col-md-2 col-sm-4 col-xs-6"
                                ><b>Placa:</b>
                              </label>
                              <span
                                class="col-md-10 col-sm-8 col-xs-6 text-right"
                              >
                                {{
                                  pdf.viaje_id && pdf.viaje_type == "App\\Viaje"
                                    ? pdf.viaje.vehiculo.placa
                                    : pdf.viaje_id &&
                                      pdf.viaje_type == "App\\DetGuiaRegalias"
                                    ? pdf.viaje.vehiculo
                                    : "---------"
                                }}
                              </span>
                            </div>
                            <div class="row" style="height: 20px">
                              <label class="col-md-2 col-sm-4 col-xs-6"
                                ><b>Empresa:</b>
                              </label>
                              <span
                                class="col-md-10 col-sm-8 col-xs-6 text-right"
                              >
                                {{
                                  pdf.viaje_id
                                    ? pdf.viaje.transportadora.razon_social
                                    : "---------"
                                }}
                              </span>
                            </div>
                            <div class="row" style="height: 20px">
                              <label class="col-md-4 col-sm-4 col-xs-6"
                                ><b>Porducto Liquido:</b>
                              </label>
                              <span
                                class="col-md-8 col-sm-8 col-xs-6 text-right"
                              >
                                {{
                                  pdf.viaje_id
                                    ? pdf.viaje.producto_liquido.nombre
                                    : "---------"
                                }}
                              </span>
                            </div>
                            <div class="row" style="height: 20px">
                              <label class="col-md-4 col-sm-4 col-xs-6"
                                ><b>Fecha Cargue:</b>
                              </label>
                              <span
                                class="col-md-8 col-sm-8 col-xs-6 text-right"
                              >
                                {{
                                  pdf.viaje_id
                                    ? pdf.viaje.fecha_cargue
                                    : "---------"
                                }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card col-sm-6" v-if="visualizacion">
                    <iframe
                      v-if="tipo_archivo == 'pdf'"
                      :src="visualizacion"
                      style="width: 100%; height: 650px"
                      frameborder="0"
                    ></iframe>
                    <img
                      v-if="tipo_archivo == 'img'"
                      :src="visualizacion"
                      style="width: 100%; height: 650px"
                    />
                  </div>
                </div>
                <div class="row" v-if="listaPdfs.length > 0">
                  <div class="form-group col-4">
                    <div class="row ml-1">
                      <button
                        type="button"
                        class="btn btn-sm bg-primary col-3"
                        v-if="
                          countCargar != 0 &&
                            $store.getters.can(
                              'hidrocarburos.escanerGuias.saveDefinitivo'
                            )
                        "
                        @click="saveDefinitivo()"
                      >
                        <i class="fa fa-file-upload"></i> <br />
                        Confirmar
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm bg-danger ml-1 col-2"
                        title="Cancelar Cambio"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.escanerGuias.descartar'
                          )
                        "
                        @click="descartarPdf()"
                      >
                        <i class="fas fa-eraser"></i> <br />
                        Borrar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
export default {
  name: "EscanerGuiasIndex",
  components: {
    Loading,
  },
  data() {
    return {
      cargando: false,
      loading: false,
      countSinGuias: 0,
      countExistentes: 0,
      countCargar: 0,
      filtros: {
        viaje_id: null,
      },
      visible: 0,
      listaPdfs: {},
      listaPdfsFiltro: {},
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
      visualizacion: null,
      tipo_archivo: null,
      progress: null,
    };
  },

  methods: {
    getIndex(page = 1) {
      var vm = this;
      vm.progress = null;
      vm.cargando = true;
      axios
        .get("api/hidrocarburos/escanerGuias?page=" + page, {
          params: vm.filtros,
        })
        .then((response) => {
          vm.listaPdfs = response.data;
          vm.countSinGuias = vm.listaPdfs.filter((item) => !item.link).length;
          vm.countExistentes = vm.listaPdfs.filter(
            (item) => item.estado === 3
          ).length;
          vm.countCargar =
            vm.listaPdfs.length - (vm.countSinGuias + vm.countExistentes);
          vm.filtro();
        });

      vm.cargando = false;
    },

    filtro(valor) {
      this.visualizacion = null;
      this.progress = null;
      this.cargando = true;
      switch (valor) {
        case 1:
          this.listaPdfsFiltro = this.listaPdfs.filter(
            (item) => item.estado === 1 && item.viaje_id != null
          );
          break;
        case 2:
          this.listaPdfsFiltro = this.listaPdfs.filter(
            (item) => item.estado === 3
          );
          break;
        case 3:
          this.listaPdfsFiltro = this.listaPdfs.filter((item) => !item.link);
          break;

        default:
          this.listaPdfsFiltro = this.listaPdfs;
          break;
      }
      this.cargando = false;
    },

    elegirDirectorio() {
      this.files = this.$refs.link_pdf.files;
    },

    async visualizarPdf(link, estado) {
      this.cargando = true;
      if ((await link) && estado != 3) {
        this.visualizacion = null;
        this.tipo_archivo = null;
        if (
          (await link.toLowerCase().endsWith(".pdf")) ||
          (await link.toLowerCase().endsWith(".PDF"))
        ) {
          this.tipo_archivo = "pdf";
        }
        if (
          (await link.toLowerCase().endsWith(".jpg")) ||
          (await link.toLowerCase().endsWith(".png")) ||
          (await link.toLowerCase().endsWith(".JPG")) ||
          (await link.toLowerCase().endsWith(".PNG"))
        ) {
          this.tipo_archivo = "img";
        }
        this.visualizacion =
          this.tipo_archivo == "pdf"
            ? "https://docs.google.com/gview?url=" +
              this.uri_docs +
              (await link) +
              "&embedded=true"
            : this.tipo_archivo == "img"
            ? this.uri_docs + (await link)
            : null;
      } else {
        this.visualizacion = null;
        estado != 3
          ? this.$swal({
              icon: "error",
              title:
                "El archivo no se puede visualizar, ya que no cuenta con un viaje válido.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            })
          : this.$swal({
              icon: "warning",
              title:
                "Para visualizar este pdf debera dar click sobre el botón: " +
                " <span class='btn btn-sm bg-danger ml-2 color-white'> <i class=' fas fa-file-pdf'></i> </span>",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
      }
      this.cargando = false;
    },

    saveFiles() {
      var vm = this;
      vm.progress = null;
      vm.loading = true;
      var total = 0;
      var count = 0;
      let formData = new FormData();
      if (vm.files) {
        total = parseFloat(vm.files.length);
        for (var i = 0; i < vm.files.length; i++) {
          let file = vm.files[i];
          formData.append(
            "name_pdf" + "-" + i,
            file.name.replace(/\.[^/.]+$/, "")
          );
          formData.append("link_pdf" + "-" + i, file);
          formData.append("ext" + "-" + i, "." + file.name.split(".").pop());

          count = i + 1;
        }
        formData.append("total", count);
        if (count > 50) {
          vm.loading = false;
          this.$swal({
            icon: "error",
            title: "No es posible cargar más de 50 archivos al tiempo.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        } else {
          axios
            .post("api/hidrocarburos/escanerGuias/cargarArchivos", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: (progressEvent) => {
                vm.progress = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
              },
            })
            .then((response) => {
              vm.files = null;
              vm.$refs.link_pdf.files = null;
              let inputPdf = document.getElementById("link_pdf");
              inputPdf.value = "";
              vm.listaPdfs = response.data;
              vm.countSinGuias = vm.listaPdfs.filter(
                (item) => !item.link
              ).length;
              vm.countExistentes = vm.listaPdfs.filter(
                (item) => item.estado === 3
              ).length;
              vm.countCargar =
                vm.listaPdfs.length - (vm.countSinGuias + vm.countExistentes);
              vm.progress == 100 ? (vm.loading = false) : (vm.loading = true);
              vm.filtro();

              vm.$swal({
                icon: "success",
                title: "Los archivos se cargaron correctamente",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch(function(error) {
              vm.$swal({
                icon: "error",
                title: "Ocurrio un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      } else {
        vm.loading = false;
        vm.$swal({
          icon: "error",
          title: "Para guardar debe seleccionar al menos un archivo",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    saveDefinitivo() {
      let data = {};
      this.progress = null;
      this.listaPdfs.forEach((pdf, index) => {
        data[index] = {
          id: pdf.id,
          viaje_id: pdf.viaje_id,
          viaje_type: pdf.viaje_type,
          link: pdf.link,
          estado: pdf.estado,
        };
      });

      this.$swal({
        title: "Esta seguro de guardar cambios?",
        text: "Solo los archivos que están por confirmar serán guardados.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Entendido.",
      }).then((result) => {
        if (result.value) {
          axios
            .post("api/hidrocarburos/escanerGuias/saveDefinitivo", data)
            .then((response) => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Cargue definitivo exitoso.",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch(function(error) {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    descartarPdf(pdf) {
      let data = {};
      this.progress = null;
      let title = "";
      if (pdf) {
        data = { 0: pdf };
        title = "Está seguro de eliminar este archivo?";
      } else {
        data = this.listaPdfs;
        title = "Está seguro de eliminar todos los archivos cargados?";
      }
      this.$swal({
        title: title,
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.visualizacion = null;
          axios
            .post("/api/hidrocarburos/escanerGuias/descartarPdf", data)
            .then(() => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Archivos seleccionados eliminados correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch(function(error) {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },

  mounted() {
    this.getIndex();
  },
};
</script>
